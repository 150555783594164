// var $ = require('jquery');

(function() {

/* On Load: Add transition delay to prevent all items loading at once */

const $modules = $('.item--animate, .item--fade');

$(window).on('load', function() {

  $('body').removeClass('loading');
  
  $modules.each((index, item) => {
    const $el = $(item);
    var elemTop = $el.offset().top;
    var elemBottom = elemTop + $el.height();
    if($el.visible(true) || $(window).scrollTop() > elemBottom || $(window).scrollTop() > elemTop) {
      $el.css('transition-delay', (0.12 * index) + 's');
      $el
        .addClass('item--visible');
      }
  });
});

})($);